import random from 'lodash/random';

const points = [];
const totalPoints = 30;
const PARTICLE_SIZE = 5;

export const getRandomInRange = (min, max) => {
  return Math.random() * (max - min) + min;
};

export const getRandomPipes = ({ width, height, i }) => {
  const pinkPalette = {
    red: [199, 255],
    green: [20, 192],
    blue: [133, 203],
  };
  const posX = PARTICLE_SIZE / 2 + Math.random() * (width - PARTICLE_SIZE / 2);
  let posY = PARTICLE_SIZE / 2 + Math.random() * (height - PARTICLE_SIZE / 2);
  const speed = 2;
  const directionX = -speed + Math.random() * speed * 2;
  const directionY = -speed + Math.random() * speed * 2;
  const connectDistance = random(30, 70);

  return {
    position: { x: posX, y: posY },
    size: PARTICLE_SIZE,
    directionX,
    directionY,
    targetX: posX,
    targetY: posY,
    index: i,
    connectDistance,
    fillColor: `${getRandomInRange(
      pinkPalette['red'][0],
      pinkPalette['red'][1]
    )}, ${getRandomInRange(
      pinkPalette['green'][0],
      pinkPalette['green'][1]
    )}, ${getRandomInRange(pinkPalette['blue'][0], pinkPalette['blue'][1])}`,
  };
};

export const getPipesToFillZone = ({ height, width }) => {
  for (let i = points.length; i < totalPoints; i++) {
    points.push(getRandomPipes({ width, height, i }));
  }

  return [...points];
};
