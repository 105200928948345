import React from 'react';
import styled from 'styled-components';
import logo from 'logo.svg';
import logoWhite from 'logo_white.svg';
import { ContentContainer, Wrapper } from 'Components/Layouts';
import ThemeSwitch from 'Components/ThemeSwitch';

const Head = styled.header`
  display: flex;
  justify-content: start;
  margin-top: 32px;
`;

const H1 = styled.h1`
  font-size: 48px;
  line-height: 64px;
`;

// const H2 = styled.h2`
//   font-size: 32px;
//   line-height: 48px;
// `;

const H3 = styled.h3`
  font-size: 24px;
  line-height: 32px;
`;

const HeadWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 56px;
`;

const SocialLinks = styled.div`
  display: flex;
  flex-direction: column;
`;

const A = styled.a`
  &:first-child {
    margin-bottom: 4px;
  }
  &:last-child {
    margin-top: 4px;
  }
`;

function Header({ theme, toggleTheme }) {
  return (
    <Head>
      <ContentContainer>
        <Wrapper>
          <ThemeSwitch theme={theme} toggleTheme={toggleTheme} />
        </Wrapper>
        <HeadWrapper>
          <img src={theme === 'light' ? logo : logoWhite} alt="logo" />
          <SocialLinks>
            <A href="https://github.com/jhonm" target="_blank" rel="noopener">
              Github
            </A>
            <A
              href="https://twitter.com/jhonmajoor"
              target="_blank"
              rel="noopener"
            >
              Twitter
            </A>
          </SocialLinks>
        </HeadWrapper>
        <Wrapper>
          <H1>
            Blancframe is an online portfolio by Jhon Majoor, a Front-end
            Developer from Amsterdam
          </H1>
          <H3>
            My main goal is to create Rich User Experiences and Accessible
            applications for the web. My starting point is to make applications
            which are simple, user friendly, and technically proficient.
          </H3>
        </Wrapper>
      </ContentContainer>
    </Head>
  );
}

export default Header;
