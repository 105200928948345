import { useCanvas, useAnimation } from '../../Canvas';

export function Pipe({
  position,
  size,
  directionX,
  directionY,
  index,
  connectDistance,
  fillColor,
  height,
  width,
  particles,
}) {
  const context = useCanvas();
  const lp = { x: position.x, y: position.y };

  const animatedDirectionX = useAnimation(directionX, val => {
    if (position.x <= size / 2 || position.x >= width - 15 / 2) {
      val *= -1;
    }
    return val;
  });

  const animatedDirectionY = useAnimation(directionY, val => {
    if (position.y <= size / 2 || position.y >= height - 15 / 2) {
      val *= -1;
    }
    return val;
  });
  const distance = (point, other) => {
    return Math.sqrt((other.x - point.x) ** 2 + (other.y - point.y) ** 2);
  };

  position.x -= animatedDirectionX;
  position.y -= animatedDirectionY;

  if (context !== null) {
    context.beginPath();
    context.fillStyle = `rgba(${fillColor}, 0.4)`;
    context.lineWidth = size;
    context.moveTo(lp.x, lp.y);
    context.arc(position.x, position.y, size / 2, 0, Math.PI * 2, true);
    context.closePath();
    context.fill();

    for (let s = 0; s < particles.length; s++) {
      let bounceParticle = particles[s];

      if (bounceParticle.index != index) {
        const d = distance(position, particles[s].position);

        if (d < 50) {
          context.beginPath();
          context.lineWidth = 1;
          context.arc(position.x, position.y, 10, 0, 2 * Math.PI);
          context.strokeStyle = `rgba(${fillColor}, ${Math.abs(
            d / connectDistance - 1
          )})`;
          context.stroke();
        }
      }
    }
  }

  return null;
}
