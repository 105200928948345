import React from 'react';
import styled, { keyframes, css } from 'styled-components';

const stretchAnimation = keyframes`
 0% {
   transform: scale(0.3);
   opacity: 0;
 }

 100% {
   transform: scale(1);
   opacity: 1;
 }
`;

const stretchAnimationBackwards = keyframes`
 0% {
   transform: scale(1);
   opacity: 1;
 }

 100% {
   transform: scale(0.1);
   opacity: 0;
 }
`;

const Container = styled.div`
  transform: scale(0);

  ${({ animate }) =>
    animate === 'show'
      ? css`
          animation: ${stretchAnimation} 200ms
            cubic-bezier(0.445, 0.05, 0.55, 0.95);
          animation-fill-mode: forwards;
        `
      : animate === 'hide'
      ? css`
          animation: ${stretchAnimationBackwards} 150ms
            cubic-bezier(0.445, 0.05, 0.55, 0.95);
          animation-fill-mode: forwards;
        `
      : ''}
`;

export const Stretch = ({ children, animate, animateBack }) => (
  <Container animate={animate} animateBack={animateBack}>
    {children}
  </Container>
);
