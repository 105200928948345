import React from 'react';
// import { func, string } from 'prop-types';
import styled from 'styled-components';

const CircleContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const Circle = styled.a`
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background: ${({ color }) => color};
  cursor: pointer;
`;

const ThemeSwitch = ({ theme, toggleTheme }) => {
  const isLight = theme === 'light';

  const onDown = e => {
    if (e.keyCode === 13 || e.keyCode === 32) {
      toggleTheme();
    }
  };

  return (
    <CircleContainer>
      <Circle
        onClick={toggleTheme}
        onKeyDown={e => onDown(e)}
        color={isLight ? '#1f1e1e' : '#fbfbfe'}
        title={isLight ? 'Switch to dark mode' : 'Switch to light mode'}
        tabIndex="0"
      />
    </CircleContainer>
  );
};

export default ThemeSwitch;
