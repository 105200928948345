import styled from 'styled-components';
import Resolution from 'common/Resolution';

export const ContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;

  ${Resolution.tablet`
    width: 60%;
    margin: 16px auto;
  `}
`;

export const Wrapper = styled.div`
  display: block;
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;

  ${Resolution.tablet`
    padding-right: 0;
    padding-left: 0;
  `}
`;
