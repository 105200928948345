import { useEffect, useState } from 'react';

export const usePlayAnimation = () => {
  const [playAnimation, setPlayAnimation] = useState(true);
  const [componentMounted, setComponentMounted] = useState(false);
  const setModePlayAnimation = name => {
    window.localStorage.setItem('playAnimation', name);
    setPlayAnimation(name);
  };

  const changePlayAnimation = val => {
    setModePlayAnimation(val);
  };

  useEffect(() => {
    const localCanvasName = window.localStorage.getItem('playAnimation');
    localCanvasName
      ? setPlayAnimation(localCanvasName === 'true' ? true : false)
      : setModePlayAnimation(true);

    setComponentMounted(true);
  }, []);

  return [playAnimation, changePlayAnimation, componentMounted];
};
