// credits to Thibaut Dutartre => https://twitter.com/thib_thib

import React, {
  createContext,
  useContext,
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
} from 'react';
import random from 'lodash/random';

const CanvasContext = createContext(null);
const FrameContext = createContext(0);

export const Canvas = ({
  height,
  width,
  dpr,
  isAnimating,
  children,
  image,
}) => {
  const canvasRef = useRef(null);
  const actualWidth = width * dpr;
  const actualHeight = height * dpr;
  // const img = useRef(null);

  // store the canvas once it's created
  const [context, setContext] = useState(null);

  useEffect(() => {
    if (canvasRef.current !== null) {
      const canvasContext = canvasRef.current.getContext('2d');
      if (canvasContext !== null) {
        canvasContext.scale(dpr, dpr);
        canvasContext.globalCompositeOperation = 'soft-light';
        setContext(canvasContext);
      }
    }
  }, [dpr]);

  // making the component and the context re-render at every frame.
  const [frameCount, setFrameCount] = useState(0);
  useEffect(() => {
    let frameId;
    if (isAnimating) {
      frameId = requestAnimationFrame(() => {
        setFrameCount(frameCount + 1);
      });
    }

    return () => {
      cancelAnimationFrame(frameId);
    };
  }, [isAnimating, frameCount, setFrameCount]);

  useEffect(() => {
    if (image) {
      const img = new Image();
      img.src = image;
      img.onload = () => {
        // context.drawImage(image, 0, 0, width, height);
      };
    }
  }, [image]);

  // automatically clear canvas when dimensions change
  // we need to re-draw all it's children;
  useLayoutEffect(() => {
    setFrameCount(random(1, true));
  }, [width, height]);

  // we need to re-draw the whole canvas
  if (context !== null) {
    context.clearRect(0, 0, actualWidth, actualHeight);
  }

  return (
    <CanvasContext.Provider value={context}>
      <FrameContext.Provider value={frameCount}>
        <canvas
          ref={canvasRef}
          height={actualHeight}
          width={actualWidth}
          image={image}
          style={{ width, height }}
        />
        {children}
      </FrameContext.Provider>
    </CanvasContext.Provider>
  );
};

export const useCanvas = () => {
  useContext(FrameContext);
  const renderingContext = useContext(CanvasContext);
  return renderingContext;
};

export const useAnimation = (initialValue, valueUpdater) => {
  const animatedValue = useRef(initialValue);
  animatedValue.current = valueUpdater(animatedValue.current);
  return animatedValue.current;
};

export const useImage = () => {
  const imageValue = useRef(null);
  return imageValue;
};
