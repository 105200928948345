import React from 'react';
import { getPipesToFillZone } from './helpers';
import { Pipe } from './pipe';

export const Pipeline = ({ height, width, dpr }) => {
  return getPipesToFillZone({
    height: height * dpr,
    width: width * dpr,
  }).map((pipeline, index) => (
    <Pipe
      key={index}
      {...pipeline}
      height={height}
      width={width}
      particles={getPipesToFillZone({
        height: height * dpr,
        width: width + dpr,
      })}
    />
  ));
};
