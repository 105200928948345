import React from 'react';
import styled from 'styled-components';
import { useDimensions } from 'Utils/use-dimensions';
import { Canvas } from 'Components/Canvas';

const Container = styled.div`
  height: 100vh;
  width: 100vw;
`;

function HomeCanvas({ CanvasComponent, animate }) {
  const [ref, { width, height, dpr }] = useDimensions();

  return (
    <Container ref={ref}>
      {width === undefined || height === undefined || dpr === undefined ? (
        <div>{'- _ -'}</div>
      ) : (
        <Canvas width={width} height={height} dpr={dpr} isAnimating={animate}>
          <CanvasComponent height={height} width={width} dpr={dpr} />
        </Canvas>
      )}
    </Container>
  );
}

export default HomeCanvas;
