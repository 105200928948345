import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import ReactGA from 'react-ga';
import { GlobalStyle } from 'global-styles';
import { Main } from 'Components/Main';
import Header from 'Components/Header';
import HomeCanvas from 'Components/HomeCanvas';
import CanvasSwitcher from 'Components/CanvasSwitcher';
import { ContentContainer, Wrapper } from 'Components/Layouts';
import { useDarkMode } from 'Hooks/useDarkMode';
import { useCanvasName } from 'Hooks/useCanvasName';
import { usePlayAnimation } from 'Hooks/usePlayAnimation';
import { lightTheme, darkTheme } from 'Utils/theme';
import { canvasOptions } from 'Utils/canvas-options';

function initializeReactGA() {
  ReactGA.initialize('UA-67460530-1');
  ReactGA.pageview('/homepage');
}

const Container = styled.div`
  display; flex;
  justify-Content: center;
  max-width: 100%;
  z-index: 3000;
`;

const List = styled.ul`
  display: flex;
  padding-left: 4px;
  flex-direction: column;
`;
const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;
const A = styled.a``;

const FixedFooter = styled.div`
  position: fixed;
  bottom: 32px;
  right: 32px;
`;

const buildOptions = () => {
  const optionArray = [];
  for (const [key, value] of Object.entries(canvasOptions)) {
    optionArray.push({ name: key, value: value.name });
  }
  return optionArray;
};

function App() {
  const [theme, toggleTheme] = useDarkMode();
  const [name, changeName, componentMounted] = useCanvasName();
  const [playAnimation, changePlayAnimation] = usePlayAnimation();
  const themeMode = theme === 'light' ? lightTheme : darkTheme;
  const canvasMode = canvasOptions[name];
  const options = buildOptions();

  initializeReactGA();

  if (!componentMounted) {
    return <div />;
  }

  return (
    <ThemeProvider theme={themeMode}>
      <HomeCanvas
        CanvasComponent={canvasMode.component}
        name={name}
        animate={
          canvasMode.animationLoop ? playAnimation : canvasMode.animationLoop
        }
      />
      <Container>
        <GlobalStyle />
        <Main>
          <Header theme={theme} toggleTheme={toggleTheme} />
          <ContentContainer>
            <Wrapper>
              <h3>Some projects I am working on</h3>
              <List>
                <ListItem>
                  <A
                    href="https://github.com/Blancframe/ember-blanc-slider"
                    target="_blank"
                    rel="noopener"
                  >
                    Ember blanc slider
                  </A>
                </ListItem>
                <ListItem>
                  <A
                    href="https://modalblanc.blancframe.com"
                    target="_blank"
                    rel="noopener"
                  >
                    Modalblanc
                  </A>
                </ListItem>
              </List>
            </Wrapper>
          </ContentContainer>
        </Main>
        <FixedFooter>
          <CanvasSwitcher
            options={options}
            clickCallback={changeName}
            name={name}
            isAnimating={
              canvasMode.animationLoop
                ? playAnimation
                : canvasMode.animationLoop
            }
            disabled={!canvasMode.animationLoop}
            changeAnimationCallback={changePlayAnimation}
          />
        </FixedFooter>
      </Container>
    </ThemeProvider>
  );
}

export default App;
