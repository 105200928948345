import { useEffect, useState } from 'react';

export const useCanvasName = () => {
  const [name, setName] = useState('polygon');
  const [componentMounted, setComponentMounted] = useState(false);
  const setModeName = name => {
    window.localStorage.setItem('currentCanvas', name);
    setName(name);
  };

  const changeName = val => {
    setModeName(val);
  };

  useEffect(() => {
    const localCanvasName = window.localStorage.getItem('currentCanvas');
    localCanvasName ? setName(localCanvasName) : setModeName('polygon');

    setComponentMounted(true);
  }, []);

  return [name, changeName, componentMounted];
};
