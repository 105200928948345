import React from 'react';
import styled, { css } from 'styled-components';

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
`;

const Checkbox = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  ${({ disabled }) =>
    disabled
      ? css`
          background-color: #e4e4e4;
          cursor: not-allowed;
        `
      : css`
          background-color: ${({ isNotChecked, theme }) =>
            isNotChecked ? '#555' : theme.particleColors};
        `}
  ${props =>
    props.round ? 'border-radius: 34px;' : ''}
  -webkit-transition: 0.4s;
  transition: 0.4s;

  &:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    ${props => (props.round ? 'border-radius: 50%;' : '')}
    -webkit-transition: 0.4s;
    transition: 0.4s;
    transform: ${props =>
      props.isNotChecked ? 'translateX(0px)' : 'translateX(26px)'};
  }
`;

const Toggle = ({ status, onClick, round, disabled }) => {
  const onDown = e => {
    if (e.keyCode === 13 || e.keyCode === 32) {
      onClick();
    }
  };

  return (
    <Switch isNotChecked={status}>
      <Checkbox
        type="checkbox"
        value="toggle"
        defaultChecked={status}
        onClick={onClick}
        disabled={disabled}
      />
      <Slider
        isNotChecked={status}
        round={round}
        disabled={disabled}
        onKeyDown={e => onDown(e)}
        tabIndex={disabled ? '-1' : '0'}
      />
    </Switch>
  );
};

export default Toggle;
