import { Polygon } from 'Components/Particles/Polygon';
import { Pipeline } from 'Components/Particles/Pipeline';
// import { Hexagon } from 'Components/Hexagon';

export const canvasOptions = {
  polygon: {
    component: Polygon,
    name: 'polygon',
    animationLoop: false,
  },
  pipeline: {
    component: Pipeline,
    name: 'pipeline',
    animationLoop: true,
  },
  // hexagon: {
  //   component: Hexagon,
  //   name: 'hexagon',
  //   animationLoop: true,
  // },
};
