import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ArrowLeft, ArrowRight } from 'Components/Icons';

const Container = styled.div`
  display: flex;
`;

const BaseButton = styled.div`
  color: ${({ disabled }) => (disabled ? '#e4e4e4' : 'black')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ disabled }) => (disabled ? '#f4f4f4' : '#DCDEE1')};
  height: 30px;
`;

const PrevButton = styled(BaseButton)`
  width: 30px;
  border-radius: 50% 0 0 50%;
`;

const NextButton = styled(BaseButton)`
  width: 30px;
  border-radius: 0 50% 50% 0;
`;

const ViewedItem = styled(BaseButton)`
  padding-right: 4px;
  padding-left: 4px;
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
`;

function NavCarousel({ name, data, updateOption }) {
  const [currentOption, updateItem] = useState(name);
  const [itemIndex, updateItemIndex] = useState();

  useEffect(() => {
    updateItem(currentOption);
  });

  useEffect(() => {
    const currentIndex = data.findIndex(d => d.name === name);
    updateItemIndex(currentIndex);
  });

  const handleNext = () => {
    const currentIndex = data.findIndex(d => d.name === name);
    const nextIndex = (currentIndex + 1) % data.length;

    if (nextIndex > 0) {
      updateItem(data[nextIndex].name);
      updateOption(data[nextIndex].name);
    }

    updateItemIndex(nextIndex);
  };

  const handlePrev = () => {
    const currentIndex = data.findIndex(d => d.name === name);
    const nextIndex = (currentIndex - 1) % data.length;

    if (nextIndex >= 0) {
      updateItem(data[nextIndex].name);
      updateOption(data[nextIndex].name);
    }

    updateItemIndex(nextIndex);
  };

  const onDownHandleNext = e => {
    if (e.keyCode === 13 || e.keyCode === 32) {
      handleNext();
    }
  };

  const onDownHandlePrev = e => {
    if (e.keyCode === 13 || e.keyCode === 32) {
      handlePrev();
    }
  };

  return (
    <Container tabIndex="0">
      <PrevButton
        onClick={handlePrev}
        onKeyDown={onDownHandlePrev}
        tabIndex="0"
        role="button"
        disabled={itemIndex === 0}
        aria-label="Previous Navigation"
      >
        <ArrowLeft />
      </PrevButton>
      <ViewedItem>{currentOption}</ViewedItem>
      <NextButton
        onClick={handleNext}
        onKeyDown={onDownHandleNext}
        tabIndex="0"
        role="button"
        disabled={itemIndex === data.length - 1}
        aria-label="Next Navigation"
      >
        <ArrowRight />
      </NextButton>
    </Container>
  );
}

export default NavCarousel;
