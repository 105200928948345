import styled from 'styled-components';
import theme from 'styled-theming';

const backgroundColor = theme('mode', {
  light: '#fafafa',
  dark: '#222',
});

export const Main = styled.main`
  background-color: ${backgroundColor};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
