import React, { useState } from 'react';
import styled from 'styled-components';
import NavCarousel from 'Components/NavCarousel';
import { Setting as SettingIcon } from 'Components/Icons';
import { Stretch } from 'Components/Animations';
import Toggle from 'Components/Toggle';
import OutsideClick from 'Components/OutsideClick';

const Container = styled.div`
  position: relative;
`;

const SwitcherContainer = styled.div`
  position: absolute;
  right: 24px;
  bottom: 24px;
  z-index: 999;
  padding: 16px;
  background-color: ${({ theme }) => theme.body};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.032),
    0 6.7px 5.5px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.006),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
  height: auto;
  width: 140px;
`;

const H3 = styled.h3`
  color: ${({ theme }) => theme.text};
`;

const Label = styled.label`
  color: ${({ theme }) => theme.text};
  display: block;
  margin-bottom: 4px;
  text-align: center;
`;

const Section = styled.div`
  margin-bottom: 16px;
  text-align: center;
`;

function CanvasSwitcher({
  options,
  clickCallback,
  name,
  isAnimating,
  changeAnimationCallback,
  disabled,
}) {
  const [show, setShow] = useState(null);

  const handleShow = () => {
    if (show === 'show') {
      setShow('hide');
    } else {
      setShow('show');
    }
  };

  const handleHide = () => {
    if (show === 'show') {
      setShow('hide');
    }
  };

  const onDown = e => {
    if (e.keyCode === 13 || e.keyCode === 32) {
      handleShow();
    }
  };

  const handleAnimation = () => {
    if (isAnimating) {
      changeAnimationCallback(false);
    } else {
      changeAnimationCallback(true);
    }
  };

  return (
    <Container>
      <div onClick={handleShow} onKeyDown={e => onDown(e)} tabIndex="0">
        <SettingIcon />
      </div>
      <OutsideClick callback={handleHide}>
        <Stretch animate={show}>
          <SwitcherContainer>
            <H3>Settings</H3>
            <Section>
              <Label>Background</Label>
              <NavCarousel
                name={name}
                data={options}
                updateOption={clickCallback}
              />
            </Section>
            <Section>
              <Label>Animating</Label>
              <Toggle
                round={true}
                onClick={() => handleAnimation()}
                status={!isAnimating}
                disabled={disabled}
              />
            </Section>
          </SwitcherContainer>
        </Stretch>
      </OutsideClick>
    </Container>
  );
}

export default CanvasSwitcher;
