import { getRandomInRange } from 'Components/Particles/Pipeline/helpers';

const pinkPalette = {
  red: [199, 255],
  green: [20, 192],
  blue: [133, 203],
};

export const lightTheme = {
  body: '#fbfbfe',
  text: '#444',
  activeLinkColor: '#555',
  particleColors: `rgb(${getRandomInRange(
    pinkPalette['red'][0],
    pinkPalette['red'][1]
  )}, ${getRandomInRange(
    pinkPalette['green'][0],
    pinkPalette['green'][1]
  )}, ${getRandomInRange(pinkPalette['blue'][0], pinkPalette['blue'][1])})`,
};

export const darkTheme = {
  body: '#1f1e1e',
  text: '#fbfbfe',
  activeLinkColor: 'steelblue',
  particleColors: `rgb(${getRandomInRange(
    pinkPalette['red'][0],
    pinkPalette['red'][1]
  )}, ${getRandomInRange(
    pinkPalette['green'][0],
    pinkPalette['green'][1]
  )}, ${getRandomInRange(pinkPalette['blue'][0], pinkPalette['blue'][1])})`,
};
