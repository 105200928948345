import { useEffect } from 'react';
import { useCanvas } from '../../Canvas';

export function Poly() {
  const context = useCanvas();

  if (context !== null) {
    const polygon = new PolyG(context);
    polygon.setup();
  }

  return null;
}

class PolyG {
  constructor(context) {
    this.canvas = context.canvas;
    this.context = context;
    this.width = window.innerWidth;
    this.height = window.innerHeight;
    this.r = 0;
    this.u = Math.PI * 2;
    this.v = Math.cos;
    this.pinkPalette = {
      red: [199, 255],
      green: [20, 192],
      blue: [133, 203],
    };
  }

  setup() {
    this.context.clearRect(0, 0, this.width, this.height);
    this.q = [
      { x: 0, y: this.height * 0.7 + 90 },
      { x: 0, y: this.height * 0.7 - 90 },
    ];

    while (this.q[1].x < this.width) {
      this.build(this.q[0], this.q[1]);
    }
  }

  getRadomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
  }

  build(i, j) {
    this.context.beginPath();
    this.context.moveTo(i.x, i.y);
    this.context.lineTo(j.x, j.y);
    var k = j.x + (Math.random() * 2 - 0.25) * 90,
      n = this.y(j.y);
    this.context.lineTo(k, n);
    this.context.closePath();
    this.r -= this.u / -50;
    this.context.fillStyle = `rgba(${this.getRadomInt(
      this.pinkPalette['red'][0],
      this.pinkPalette['red'][1]
    )}, ${this.getRadomInt(
      this.pinkPalette['green'][0],
      this.pinkPalette['green'][1]
    )}, ${this.getRadomInt(
      this.pinkPalette['blue'][0],
      this.pinkPalette['blue'][1]
    )}, 0.1)`;
    this.context.fill();
    this.q[0] = this.q[1];
    this.q[1] = { x: k, y: n };
  }

  y(p) {
    let t = p + (Math.random() * 2 - 1.1) * 90;
    return t > this.height || t < 0 ? this.y(p) : t;
  }
}
